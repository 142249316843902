/**
 * Application entry point
 */

// Load application styles
import 'styles/index.scss';

// ================================
// START YOUR APP HERE
// ================================


var $ = require( 'jQuery' );
var LazyLoad = require( 'vanilla-lazyload' );
var pages = require( '../scripts/src/pages.js' );
var thumbnails = [];
var $loading = $( '.loading' );
var $loadingBar = $loading.find( '.bar' );
var $thumbContainer = $( '.thumb-container' );
var $modal = $( '.modal' );
var $modalClose = $( '.modal .close' );
var $modalTitle = $modal.find( '.title' );
var $modalText = $modal.find( '.text' );
var $modalImage = $modal.find( '.image' );
var $modalVideo = $modal.find( '.video' );
var $modalVideoPlayer = $modalVideo.find( 'video' );
var $modalPageNumber = $modal.find( '.page-number' );
var $modalNext = $modal.find( '.modal-next' );
var $modalPrevious = $modal.find( '.modal-previous' );
var currentPage = 0;
var pageIndex = 0;



window.onload = function() {

	
	var pagesLoaded = 0;

	// make pages
	for( var i = 0; i < pages.length; i++ ) {
		var page = pages[ i ];
		(function( i, page ){
			$.ajax({
				url : page.url,
				success : function( data ) {
					// console.log( data );
					var thumbnail = document.createElement( 'div' );
					thumbnail.classList.add( 'thumbnail' );
					thumbnail.style.cssText = '--aspect-ratio:1;';
					var thumbImage = document.createElement( 'div' );
					thumbImage.classList.add( 'thumb-image' );
					thumbImage.dataset.image = data.image
					thumbImage.dataset.src = 'assets/images/thumb/' + data.image;
					thumbImage.dataset.number = data.image.slice( 0, -4 );
					thumbImage.dataset.index = thumbnail.index = i;
					thumbImage.title = data.title;
					thumbImage.text = data.text;
					thumbnail.style.order = thumbImage.dataset.index + 1;
					if( data.video ) {
						thumbImage.classList.add( 'video' );
						thumbImage.dataset.video = 'true';
					}
					$( thumbnail ).on( 'mouseup touchup', clickThumb );
					thumbnail.appendChild( thumbImage );
					thumbnails.push( thumbnail );
					pagesLoaded ++;
					$loadingBar.css( 'width', 100 * ( pagesLoaded / ( pages.length - 1 ) )  + '%' );
					if( pagesLoaded == pages.length ) next();
				}
			});
		})( i, page );
	}

}

var next = function( cb ) {

	// sort thumbnails
	thumbnails.sort(function(a, b) {
  		return a.index - b.index;
	});

	// add all thumbs to the page
	for( var i = 0; i < thumbnails.length; i++ ) {
		var thumbnail = thumbnails[ i ];
		var thumbImage = thumbnail.children[ 0 ];
		if( thumbImage.dataset.video ) {
			var preload = document.createElement('video');
			preload.src = 'assets/images/display/' + thumbImage.dataset.image.slice( 0,-3 ) + "mp4";
		} else {
			var preload = new Image();
			preload.src = 'assets/images/display/' + thumbImage.dataset.image;
		}
		$thumbContainer[ 0 ].appendChild( thumbnail );
	}



	// console.log( 'next' );
	var myLazyLoad = new LazyLoad({
	    elements_selector: ".thumb-image"
	});

	$loading.fadeOut('fast');

}

var clickThumb = function( e ) {
	// console.log( e );
	var target = e.currentTarget.children[ 0 ];
	
	pageIndex = target.dataset.index;
	activateModal( target );
}

var activateModal = function( thumbImage ) {

	$modalImage.hide();
	$modalVideo.hide();
	$modalVideoPlayer[ 0 ].pause();

	if( thumbImage.dataset.video ) {
		$modalVideo.find('video').attr( 'src', 'assets/images/display/' + thumbImage.dataset.image.slice( 0,-3 ) + "mp4" );	
		$modalVideoPlayer[ 0 ].play();
		$modalVideo.show();
	} else {
		$modalImage.css( 'background-image', 'url( assets/images/display/' + thumbImage.dataset.image + ')' );	
		$modalImage.show();
	}
	
	// $modalTitle.text( thumbImage.title );
	if( Array.isArray( thumbImage.text ) ) {
		$modalText.text( thumbImage.text.join("\n\n") );	
	} else {
		$modalText.text( thumbImage.text );
	}
	
	currentPage = thumbImage.dataset.number;
	currentPage = "00" + currentPage;
	currentPage = currentPage.substr( currentPage.length - 3 );

	$modalPageNumber.text( ( parseFloat(pageIndex) + 1 ) );
	
	$modal.show().css('display', 'flex');;
}

var closeModal = function( ){
	$modalVideoPlayer[ 0 ].pause();
	$modal.hide();
}

var getSource = function( isVideo ) {
	
	var url;
	if( isVideo ) {
		url = 'assets/images/source/' + currentPage + '.mov'; 
	} else {
		url = 'assets/images/source/' + currentPage + '.jpg'; 
	}
	window.open( url, '_blank');

}

$modal.on( 'mouseup touchend', function( e ){
	if(e.target != this) return;
	e.preventDefault();
	closeModal();	
});

$modalClose.on( 'mouseup touchend', function( e ){
	e.preventDefault();
	closeModal();	
});

$modalImage.on( 'mouseup touchend', function( e ){
	e.preventDefault();
	getSource();
});

$modalVideo.on( 'mouseup touchend', function( e ){
	e.preventDefault();
	getSource( true );
});

$modalNext.on( 'mouseup touchend', function( e ){
	e.preventDefault();
	nextPage();
	loadPage();	
});

$modalPrevious.on( 'mouseup touchend', function( e ){
	e.preventDefault();
	previousPage();
	loadPage();	
});

var nextPage = function() {
	pageIndex ++;
	pageIndex = pageIndex % pages.length;
}

var previousPage = function() {
	pageIndex -= 1;
	if( pageIndex < 0 ) pageIndex = pages.length - 1;
}

var loadPage = function( ) {
	var page = $( '.thumb-image[data-index="' + pageIndex + '"]' )[ 0 ];
	activateModal( page );
}

document.onkeyup = function( e ) {

	// console.log( e );

	if( $modal.is(":visible") ) {

		// esc
		if( e.keyCode == 27 ) {
			closeModal( );
			return;
		}

		// next
		if( e.keyCode == 39 ) {
			nextPage();
		}

		// previous
		if( e.keyCode == 37 ) {
			previousPage();
		}

		loadPage();
	}

}